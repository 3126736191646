
import {
    Link
} from 'react-router-dom';
import "./style.scss"
import signs from "../../assets/signs.svg"
import bug01 from "../../assets/bug01.svg"
import bug03 from "../../assets/bug03.svg"
const Home = () => {

    return <>

        <div className="tb-qr">

            <div className="tb-qr__text">
                <h2 className="tb-qr__title">
                    ЭТО БАЗА!
                </h2>
                <p>
                    Будь точнее и&nbsp;быстрее, чтобы победить
                </p>
            </div>

            {/*<!-- ===============================================

              следующий блок при необходимости может быть ссылкой:

              <a href="#" class="tb-qr__qr">
                <img src="./img/qr-code.svg" class="tb-qr__qr-img" alt="QR">
              </a>

            =============================================== --> */}
            <div className="tb-btns tb-btns_02">
                <Link to="/signup" className="tb-btn tb-btn_cta">Участвовать</Link>
            </div>

            <div className="tb-qr__bottom">
                <img className="tb-signs" src={signs} alt="" />
                    <img src={bug01} alt="bug" className="tb-bug tb-bug_03" />
                        <img src={bug03} alt="bug" className="tb-bug tb-bug_04"/>
            </div>

        </div>

    </>
}

export default Home;