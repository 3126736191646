import s_bubble from "../../assets/s_bubble.svg";
import bug01 from "../../assets/bug01.svg";
import bug04 from "../../assets/bug04.svg";
import {useForm, Controller} from "react-hook-form";
import {useEffect, useContext, useState, useRef, createRef, Component} from 'react';
import {ContextState} from "../../ContextState";
import {useNavigate} from "react-router-dom";
import ReactLoading from 'react-loading';
import InputMask from "react-input-mask";
import "./style.scss";
class PhoneInput extends Component {
    render() {
        return <InputMask {...this.props} mask="+7(999)999-99-99" maskChar=" " />;
    }
}


const SignUp = () => {

    const {dispatch, state: {signUpDefaultValues = {}}} = useContext(ContextState);
    const {handleSubmit, control, watch, register, formState: {errors}} = useForm(signUpDefaultValues);
    const [checkingUser, setCheckingUser] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        setCheckingUser(true);
        fetch('/api/checkUser')
            .then((resp) => resp.json())
            .then(({success, user}) => {
                if (success && user && user.id) {
                    if (user.verified) {
                        navigate('/start');
                        dispatch({
                            type: 'setState',
                            payload: {
                                user
                            }
                        })
                    } else {
                        navigate('/verify');
                    }
                }

            })
            .finally(() => {
                setCheckingUser(false);
            })
            .catch(() => {

            })
    }, []);
    const onSubmit = (data) => {

        const formData = new FormData()
        Object.keys(data).forEach((v) => formData.append(v, data[v]));

        fetch('/api/signup', {
            method: 'post',
            body: formData
        })
            .then((resp) => resp.json())
            .then(({success, user}) => {
                if (success && user && user.id) {
                    if (user.verified) {
                        navigate('/start');
                    } else {
                        navigate('/verify');
                    }
                }

            })
            .catch(() => {

            })
    }

    const onKindaSelectTrigger = (e) => {
        e.preventDefault();
        const trigger = e.currentTarget;
        const toggleTarget = trigger.closest('.js_kindaselect');
        if (!toggleTarget.classList.contains('m-active')) {
            document.querySelectorAll('.js_kindaselect.m-active').forEach(item => {
                item.classList.remove('m-active');
            });
            toggleTarget.classList.add('m-active');
        } else {
            toggleTarget.classList.remove('m-active');
        }
    }
    const setKindaSelectValue = (thisRadio) => {
        const thisVal = thisRadio.closest('.tb-form__radio').textContent,
            thisSelect = thisRadio.closest('.js_kindaselect');
        thisSelect.querySelector('.js_kindaselect_trigger').textContent = thisVal;
        thisSelect.classList.remove('m-active');
    }
    useEffect(() => {
        document.querySelectorAll('.js_kindaselect input[type="radio"]').forEach(item => {
            // check on start
            if (item.checked) {
                setKindaSelectValue(item)
            }
            // on change
            item.addEventListener('change', (e) => {
                setKindaSelectValue(item)
            });
        });

        document.addEventListener('click', (e) => {
            if (!e.target.closest('.js_kindaselect') && document.querySelector('.js_kindaselect.m-active')) {
                document.querySelector('.js_kindaselect.m-active').classList.remove('m-active');
            }
        });

        return () => {
            document.querySelectorAll('.js_kindaselect input[type="radio"]').forEach(item => {

                // on change
                item.removeEventListener('change', () => {
                    setKindaSelectValue(item)
                });
            });

            document.removeEventListener('click', (e) => {
                if (!e.target.closest('.js_kindaselect') && document.querySelector('.js_kindaselect.m-active')) {
                    document.querySelector('.js_kindaselect.m-active').classList.remove('m-active');
                }
            });
        }
    }, [checkingUser]);

    useEffect(() => {
        const subscription = watch((value) => dispatch({
            type: 'setState',
            payload: {
                signUpDefaultValues: value
            }
        }));

        return () => {
            subscription.unsubscribe()
        };
    }, [watch]);


    if (checkingUser) {
        return <>

            <h2 className="tb-section__title">
                РЕГИСТРАЦИЯ
            </h2>

            <div className="tb-form" style={{display: "flex", justifyContent: "center", paddingTop: "4rem"}}>
                <ReactLoading height={'20%'} width={'20%'} type="spinningBubbles"/>
            </div>
        </>
    }


    return <>

        <h2 className="tb-section__title">
            РЕГИСТРАЦИЯ
        </h2>

        <div className="tb-form">
            <form onSubmit={handleSubmit(onSubmit)}>

                {/*<!-- в макете нет алертов, но вот пример на всякий случай --> */}
                <div className={"tb-form__row" + (errors.surname ? ' is-error' : '')}>
                    <div className="tb-form__input-holder">
                        <input type="text" className="tb-form__input" {...register("surname", {
                            required: "Пожалуйста, заполните это поле",
                        })} placeholder="Фамилия" autoComplete="off"/>
                        {errors.surname && <div className="tb-form__alert">{errors.surname.message}</div>}
                    </div>
                </div>
                <div className={"tb-form__row" + (errors.name ? ' is-error' : '')}>
                    <div className="tb-form__input-holder">
                        <input type="text" className="tb-form__input" placeholder="Имя" {...register("name", {
                            required: "Пожалуйста, заполните это поле",
                        })} />
                        {errors.name && <div className="tb-form__alert">{errors.name.message}</div>}
                    </div>
                </div>
                <div className={"tb-form__row" + (errors.email ? ' is-error' : '')}>
                    <div className="tb-form__input-holder">
                        <input type="email" className="tb-form__input" placeholder="Электронная почта" {...register("email", {
                            required: "Пожалуйста, заполните это поле",
                            pattern: {
                                value: /\S+@\S+\.\S+/,
                                message: "Введенное значение не соответствует формату электронной почты"
                            }
                        })}/>
                        {errors.email && <div className="tb-form__alert">{errors.email.message}</div>}
                    </div>
                </div>
                <div className={"tb-form__row" + (errors.phone ? ' is-error' : '')}>
                    <div className="tb-form__input-holder">
                        <Controller
                            name="phone"
                            control={control}
                            rules={{
                                required: 'Введите контактный телефон',
                                pattern: {
                                    value: /^\+7\([0-9]{3}\)[0-9\-]{9}$/,
                                    message: 'Введите телефон в формате +7(000)000-00-00'
                                },
                            }}
                            render={({field: {...restField}, fieldState: {error, invalid}}) => {
                                return <>
                                    <PhoneInput
                                        placeholder="Контактный телефон"
                                        type="tel"
                                        autoComplete="off"
                                        className="tb-form__input"
                                        {...restField}
                                    />
                                    {error && <div className="tb-form__alert">{error.message}</div>}
                                </>
                            }}
                        />

                    </div>
                </div>
                <div className={"tb-form__row" + (errors.company ? ' is-error' : '')}>
                    <div className="tb-form__input-holder">
                        <input type="text" className="tb-form__input" placeholder="Компания" {...register("company", {
                            required: "Пожалуйста, заполните это поле"
                        })}/>
                        {errors.company && <div className="tb-form__alert">{errors.company.message}</div>}
                    </div>
                </div>

                <div className={"tb-form__row js_kindaselect" + (errors.experience ? ' is-error' : '')}>
                    <div className="tb-form__input-holder">
                        <button type="button" className="tb-form__radiotrigger tb-form__input js_kindaselect_trigger" onClick={onKindaSelectTrigger}>Опыт
                        </button>

                        <div className="tb-form__radiogroup">
                            <label className="tb-form__radio">
                                <input value="Junior" type="radio" name="tb-radio-exp" id="exp_Junior" {...register("experience", {
                                    required: "Пожалуйста, выберите опыт"
                                })}/>
                                <span className="tb-form__radiotext">Junior</span>
                            </label>
                            <label className="tb-form__radio">
                                <input value="Middle" type="radio" name="tb-radio-exp" id="exp_Middle" {...register("experience", {
                                    required: "Пожалуйста, выберите опыт"
                                })}/>
                                <span className="tb-form__radiotext">Middle</span>
                            </label>
                            <label className="tb-form__radio">
                                <input value="Senior" type="radio" name="tb-radio-exp" id="exp_Senior" {...register("experience", {
                                    required: "Пожалуйста, выберите опыт"
                                })}/>
                                <span className="tb-form__radiotext">Senior</span>
                            </label>
                            <label className="tb-form__radio">
                                <input value="Team Lead" type="radio" name="tb-radio-exp" id="exp_TeamLead" {...register("experience", {
                                    required: "Пожалуйста, выберите опыт"
                                })}/>
                                <span className="tb-form__radiotext">Team Lead</span>
                            </label>
                        </div>
                        {errors.experience && <div className="tb-form__alert">{errors.experience.message}</div>}
                    </div>
                </div>

                <div className={"tb-form__row js_kindaselect" + (errors.direction ? ' is-error' : '')}>
                    <div className="tb-form__input-holder">
                        <button type="button" className="tb-form__radiotrigger tb-form__input js_kindaselect_trigger"
                                onClick={onKindaSelectTrigger}>Направление
                        </button>

                        <div className="tb-form__radiogroup">
                            <label className="tb-form__radio">
                                <input value="Security Operation Center" type="radio" name="tb-radio-branch"
                                       id="branch_SecurityOperationCenter" {...register("direction", {
                                    required: "Пожалуйста, выберите направление"
                                })}/>
                                <span className="tb-form__radiotext">Security Operation Center</span>
                            </label>
                            <label className="tb-form__radio">
                                <input value="Application Security" type="radio" name="tb-radio-branch"
                                       id="branch_ApplicationSecurity" {...register("direction", {
                                    required: "Пожалуйста, выберите направление"
                                })}/>
                                <span className="tb-form__radiotext">Application Security</span>
                            </label>
                            <label className="tb-form__radio">
                                <input value="Infrastructure Security" type="radio" name="tb-radio-branch"
                                       id="branch_InfrastructureSecurity" {...register("direction", {
                                    required: "Пожалуйста, выберите направление"
                                })}/>
                                <span className="tb-form__radiotext">Infrastructure Security</span>
                            </label>
                            <label className="tb-form__radio">
                                <input value="Threat intelligence" type="radio" name="tb-radio-branch"
                                       id="branch_ThreatIntelligence" {...register("direction", {
                                    required: "Пожалуйста, выберите направление"
                                })}/>
                                <span className="tb-form__radiotext">Threat intelligence</span>
                            </label>
                            <label className="tb-form__radio">
                                <input value="Pentest" type="radio" name="tb-radio-branch" id="branch_Pentest" {...register("direction", {
                                    required: "Пожалуйста, выберите направление"
                                })}/>
                                <span className="tb-form__radiotext">Pentest</span>
                            </label>
                            <label className="tb-form__radio">
                                <input value="Другое" type="radio" name="tb-radio-branch" id="branch_Other" {...register("direction", {
                                    required: "Пожалуйста, выберите направление"
                                })}/>
                                <span className="tb-form__radiotext">Другое</span>
                            </label>
                        </div>
                        {errors.direction && <div className="tb-form__alert">{errors.direction.message}</div>}
                    </div>

                </div>


                <div className="tb-form__row">

                    <label className="tb-form__check">
                        <input type="checkbox" name="vacancyInfo" value="1"/>
                        <span className="tb-form__checktext">Я&nbsp;хочу получать информацию об&nbsp;открытых вакансиях</span>
                    </label>

                </div>

                <div className="tb-form__row tb-form__row_sg">

                    <label className="tb-form__check">
                        <input type="checkbox" required={true}/>
                        <span className="tb-form__checktext">Я принимаю&nbsp;<a href="https://ctf.tinkoff.ru/files/privacy-policy_tinkoff.pdf" target="_blank">условия передачи информации</a></span>
                    </label>

                </div>

                <div className="tb-btns tb-form__btns">

                    <img src={s_bubble} alt="..." className="tb-bubble tb-bubble_01"/>
                    <img src={bug01} alt="bug" className="tb-bug tb-bug_05"/>
                    <img src={bug04} alt="bug" className="tb-bug tb-bug_06"/>

                    <button className="tb-btn tb-btn_cta" type="submit">Зарегистрироваться</button>

                </div>

            </form>
        </div>


    </>
}

export default SignUp;