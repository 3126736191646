import {
    Link, useNavigate
} from 'react-router-dom';
import "./style.scss"
import s_bubble from "../../assets/s_bubble.svg"
import bug01 from "../../assets/bug01.svg"
import bug03 from "../../assets/bug03.svg"
import bug04 from "../../assets/bug04.svg"
import signs2 from "../../assets/signs2.svg"
import {useContext, useEffect, useState} from "react";
import {ContextState} from "../../ContextState";
import ReactLoading from 'react-loading';

const Start = () => {
    const {dispatch, state: {user = {}}} = useContext(ContextState);
    const navigate = useNavigate();
    const [starting, setStarting] = useState(false);
    useEffect(() => {
        if (Object.keys(user).length === 0) {
            navigate('/')
        } else if (!user || !user.verified) {
            navigate('/verify')
        }
    }, []);

    const onStartButtonClick = () => {
        setStarting(true);
        fetch('/api/start')
            .then((resp) => resp.json())
            .then(({success, session, questions}) => {
                if (session.ended ){
                    navigate('/result');
                }else if (success && questions.length) {
                    dispatch({
                        type: 'setState',
                        payload: {
                            session: {...session, questions}
                        }
                    })
                    navigate('/quiz');
                } else {
                    navigate('/');
                }


            })
            .finally(() => {
                setStarting(false);
            })
            .catch(() => {

            })
    }

    return <>

        <img src={s_bubble} alt="..." className="tb-bubble tb-bubble_02"/>

        <h2 className="tb-section__title tb-section__title_huge">
            Будь точнее и&nbsp;быстрее, чтобы&nbsp;победить.
        </h2>

        <div className="tb-btns tb-btns_02">

            <img src={bug03} alt="bug" className="tb-bug tb-bug_07"/>

            <button className="tb-btn tb-btn_cta" disabled={starting} onClick={onStartButtonClick}>Начать {starting &&
                <ReactLoading style={{marginLeft: '5px', height: "3rem", width: "3rem", fill: "#fcc521"}} type="spin"/>}</button>
        </div>

        <div className="tb-section__bottom">
            <img className="tb-signs2" src={signs2} alt=""/>
            <img src={bug01} alt="bug" className="tb-bug tb-bug_08"/>
            <img src={bug04} alt="bug" className="tb-bug tb-bug_09"/>
            <img src={bug03} alt="bug" className="tb-bug tb-bug_10"/>
        </div>


    </>
}

export default Start;