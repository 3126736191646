import {
    Link, useNavigate
} from 'react-router-dom';
import "./style.scss"

import bug03 from "../../assets/bug03.svg"
import timer from "../../assets/timer.svg"
import {useContext, useEffect, useState} from "react";
import {ContextState} from "../../ContextState";
import {useForm} from "react-hook-form";
import ReactLoading from 'react-loading';

const Quiz = () => {
    const {dispatch, state: {user = {}, session = {}}} = useContext(ContextState);
    const {handleSubmit, register, watch, formState: {errors}} = useForm({});
    const navigate = useNavigate();
    const {questions = []} = session;
    const questionsLeft = questions
        .filter((v) => !v.replied)
        .map(value => ({value, sort: Math.random()}))
        .sort((a, b) => a.sort - b.sort)
        .map(({value}) => value);

    const [currentQuestion, setCurrentQuestion] = useState(questionsLeft[0] || undefined);
    const [currentSelectedVariant, setCurrentSelectedVariant] = useState();
    const [nextButtonDisabled, setNextButtonDisable] = useState(true);
    const [nextButtonLoading, setNextButtonLoading] = useState(false);
    const [timeElapsed, setTimeElapsed] = useState(0);

    useEffect(() => {
        if (Object.keys(user).length === 0) {
            navigate('/')
        } else if (!user || !user.verified) {
            navigate('/verify')
        } else if (Object.keys(session).length === 0) {
            navigate('/start')
        } else if (!questions || Object.keys(questions).length === 0) {
            navigate('/start')
        } else if (session.ended) {
            navigate('/result')
        }
    }, []);

    useEffect(() => {
        const subscription = watch((value) => {
            setCurrentSelectedVariant(value);
            setNextButtonDisable(false);
        })
        return () => {
            subscription.unsubscribe;
        }
    }, []);

    useEffect(() => {
        if (!session) {
            return;
        }
        const i = setInterval(() => {
            fetch('/api/ping')
                .then((resp) => resp.json())
                .then(({act})=> {
                    setTimeElapsed(act - session.started);
                })
        }, 1000);

        return () => {
            clearInterval(i);
        }
    }, []);

    const onNextButtonClick = () => {
        if (nextButtonLoading) {
            return;
        }
        setNextButtonLoading(true);
        const body = new FormData();
        Object.keys(currentSelectedVariant).forEach((k) => {
            body.append(k, currentSelectedVariant[k]);
        })

        fetch('/api/reply', {
            method: 'post',
            body
        })
            .then((resp) => resp.json())
            .then(({success, session, questions}) => {
                if (success) {
                    if (questions)
                        dispatch({
                            type: 'setState',
                            payload: {
                                session: {...session, questions}
                            }
                        })
                    if (session.finished) {
                        navigate('/result');
                    } else {
                        setCurrentSelectedVariant(undefined);
                        setNextButtonDisable(true);
                        setCurrentQuestion(questions.filter((v) => !v.replied)[0]);
                    }
                } else {
                    navigate('/start');
                }


            })
            .finally(() => {
                setNextButtonLoading(false);
            })
            .catch(() => {

            })
    }

    const formatTime = (interval) => {
        var sec_num = parseInt(interval, 10); // don't forget the second param
        var hours = Math.floor(sec_num / 3600);
        var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
        var seconds = sec_num - (hours * 3600) - (minutes * 60);

        return ('0' + minutes).substr(-2) + ':' + ('0' + seconds).substr(-2);
    }

    if (Object.keys(user).length === 0) {
        return <></>
    } else if (!user || !user.verified) {
        return <></>
    } else if (Object.keys(session).length === 0) {
        return <></>
    } else if (!questions || Object.keys(questions).length === 0) {
        return <></>
    } else if (session.ended) {
        return <></>
    }

    return <>

        <div className="tb-quest">

            <h2 className="tb-quest__title">
                {currentQuestion.quiz.caption}
            </h2>

            <div className="tb-quest__num">
                {questions.length - questionsLeft.length + 1} вопрос
            </div>

            <div className="tb-quest__plate">
                <div className="tb-quest__text">
                    {currentQuestion.caption && <p style={{whiteSpace: "pre-wrap"}}>
                        {currentQuestion.caption}
                    </p>}
                    {currentQuestion.image &&
                        <img className="tb-quest__pic" src={"/api/image/" + currentQuestion.image.id} alt=""/>
                    }
                </div>

                <div className="tb-quest__options">

                    {currentQuestion.replyVariants.map((item) => <label className="tb-quest__option" key={"variant" + item.id}>
                        <input  {...register('variant')} id={"variant" + item.id} type="radio" name="variant" value={item.id}/>
                        <span className="tb-quest__option-text">
                  {item.caption}
                </span>
                    </label>)}
                </div>
            </div>

            <div className="tb-quest__btns tb-btns">

                <img src={bug03} alt="bug" className="tb-bug tb-bug_11"/>

                <button className="tb-btn tb-btn_cta" disabled={nextButtonDisabled} onClick={onNextButtonClick}>Далее {nextButtonLoading &&
                    <ReactLoading style={{marginLeft: '5px', height: "3rem", width: "3rem", fill: "#fff"}} type="spin"/>}</button>

            </div>

        </div>
        <aside className="tb-timer">
            <img className="tb-timer__ico" src={timer} alt=""/>
            <div className="tb-timer__time">
                {formatTime(timeElapsed)}
            </div>
        </aside>
    </>
}

export default Quiz;