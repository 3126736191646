import {
    Link, useNavigate
} from 'react-router-dom';
import "./style.scss"
import signs2 from "../../assets/signs.svg"
import bug01 from "../../assets/bug01.svg"
import bug03 from "../../assets/bug03.svg"
import bug04 from "../../assets/bug04.svg"
import {useEffect, useState} from "react";
import ReactLoading from 'react-loading';

const Result = () => {

    const [dataLoading, setDataLoading] = useState(true);
    const [session, setSession] = useState({});
    const navigate = useNavigate();

    useEffect(() => {
        setDataLoading(true);
        fetch('/api/reply')
            .then((resp) => resp.json())
            .then(({success, session}) => {
                if (success && session) {
                    if (session.ended) {
                        setSession(session);
                    } else {
                        navigate('/start');
                    }

                } else {
                    navigate('/start');
                }

            })
            .finally(() => {
                setDataLoading(false);
            })
            .catch(() => {

            })
    }, []);

    const formatTime = (interval) => {
        var sec_num = parseInt(interval, 10); // don't forget the second param
        var hours = Math.floor(sec_num / 3600);
        var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
        var seconds = sec_num - (hours * 3600) - (minutes * 60);

        return  ('0' + minutes).substr(-2) + ':' + ('0' + seconds).substr(-2);
    }

    return <>

        {dataLoading && <div style={{display:"flex", justifyContent:"center"}}><ReactLoading type="bars" width="40%" height="40%" fill="#01082d"/></div>}
        {!dataLoading && <>
            <div className="tb-stats">

                <h2 className="tb-stats__title">
                    Спасибо за&nbsp;участие
                </h2>

                <div className="tb-stats__note">
                    Покажи этот экран на&nbsp;стенде Тинькофф только при количестве баллов от 5 и выше.
                </div>

                <div className="tb-stats__pills">

                    <div className="tb-stats__pill">
                        Кол-во правильных ответов&nbsp;&mdash; <b>{session.score}</b>
                    </div>

                    <div className="tb-stats__pill">
                        Время прохождения&nbsp;&mdash; <b>{formatTime((session.ended - session.started))}</b>
                    </div>

                </div>

            </div>


            <div className="tb-btns">
                <Link to="/leaders" className="tb-btn tb-btn_cta">Посмотреть Лидерборд</Link>
            </div>
        </>}

        <div className="tb-section__bottom">
            <img className="tb-signs2" src={signs2} alt=""/>
            <img src={bug01} alt="bug" className="tb-bug tb-bug_08"/>
            <img src={bug04} alt="bug" className="tb-bug tb-bug_09"/>
            <img src={bug03} alt="bug" className="tb-bug tb-bug_10"/>
            <img src={bug03} alt="bug" className="tb-bug tb-bug_12"/>
        </div>


    </>
}

export default Result;