import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import {
    HashRouter ,
} from 'react-router-dom';
import {ContextStateProvider} from "./ContextState";
ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
      <HashRouter >
          <ContextStateProvider>
              <App/>
          </ContextStateProvider>
      </HashRouter >
  </React.StrictMode>,
)
