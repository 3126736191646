import {
    useNavigate
} from 'react-router-dom';
import "./style.scss"
import bug03 from "../../assets/bug03.svg"
import {useEffect, useRef, useState} from "react";
import ReactLoading from 'react-loading';

const Leaders = () => {
    const [dataLoading, setDataLoading] = useState(true);
    const [leaders, setLeaders] = useState([]);
    const [user, setUser] = useState([]);
    const navigate = useNavigate();
    const scrollbars = useRef(null);
    const participantRef = useRef(null);

    useEffect(() => {
        setDataLoading(true);
        fetch('/api/leaders')
            .then((resp) => resp.json())
            .then(({success, leaders, user}) => {
                if (success) {
                    setLeaders(leaders);
                    setUser(user);
                    if (user && participantRef.current) {
                        scrollbars.current && scrollbars.current.scrollTo(0, Math.max(0, participantRef.current.offsetTop - 100 ))
                    }else {
                        scrollbars.current && scrollbars.current.scrollTo(0,0)
                    }
                } else {
                    navigate('/start');
                }

            })
            .finally(() => {
                setDataLoading(false);
            })
            .catch(() => {

            })
    }, []);

    useEffect(() => {
        document.body.classList.add('hasleaderboard')
        return () => {
            document.body.classList.remove('hasleaderboard')
        }
    }, []);

    const formatTime = (interval) => {
        var sec_num = parseInt(interval, 10); // don't forget the second param
        var hours = Math.floor(sec_num / 3600);
        var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
        var seconds = sec_num - (hours * 3600) - (minutes * 60);

        return ('0' + minutes).substr(-2) + ':' + ('0' + seconds).substr(-2);
    }

    const pluralize = (count, words) => {
        var cases = [2, 0, 1, 1, 1, 2];
        return words[(count % 100 > 4 && count % 100 < 20) ? 2 : cases[Math.min(count % 10, 5)]];
    }

    return <>

        <div className="tb-leaderboard">

            <div className="tb-leaderboard__tilte">
                Таблица лидеров
            </div>
            {dataLoading && <div style={{display: "flex", justifyContent: "center"}}><ReactLoading type="bars" width="40%" height="40%" fill="#01082d"/></div>}
            {!dataLoading && <div className="tb-leaderboard__table" ref={scrollbars}>
                {leaders.map((item) => <div className={"tb-leaderboard__tr" + (user && user.id === item.userId ? ' m-current' : '')}
                                            key={"leader-" + item.id} ref={user && user.id === item.userId ? participantRef : null}>
                    <div className="tb-leaderboard__name">
                        <span>{item.name}</span>
                        <span>{item.surname}</span>
                    </div>
                    <div className="tb-leaderboard__results">
                        <div className="tb-leaderboard__points">{item.score} {pluralize(item.score, ['ответ', 'ответа', 'ответов'])}</div>
                        <div className="tb-leaderboard__time">{formatTime(item.time)} мин</div>
                    </div>
                </div>)}
            </div>}

        </div>

        <img src={bug03} alt="bug" className="tb-bug tb-bug_13"/>

    </>
}

export default Leaders;