import {
    Link, useNavigate
} from 'react-router-dom';
import "./style.scss"
import s_bubble from "../../assets/s_bubble.svg"
import signs2 from "../../assets/signs2.svg"
import bug01 from "../../assets/bug01.svg"
import bug03 from "../../assets/bug03.svg"
import bug04 from "../../assets/bug04.svg"
import {useContext, useEffect, useRef, useState} from "react";
import {useForm} from "react-hook-form";
import {ContextState} from "../../ContextState";

const Verify = () => {
    const [timerInterval, setTimerInterval] = useState(60);
    const [resendButtonDisabled, setResendButtonDisabled] = useState(true);
    const navigate = useNavigate();
    const {dispatch} = useContext(ContextState);
    const {handleSubmit, register, setError, formState: {errors}} = useForm({});


    const formatTime = (interval) => {
        var sec_num = parseInt(interval, 10); // don't forget the second param
        var hours = Math.floor(sec_num / 3600);
        var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
        var seconds = sec_num - (hours * 3600) - (minutes * 60);

        return ('0' + minutes).substr(-2) + ':' + ('0' + seconds).substr(-2);
    }

    useEffect(() => {
        if (timerInterval <= 0) {
            setResendButtonDisabled(false);
            return;
        }

        const _timer = setTimeout(() => {
            setTimerInterval(timerInterval - 1);
        }, 1000);
        return () => {
            clearTimeout(_timer);
        }
    }, [timerInterval]);


    const resendCode = () => {
        setResendButtonDisabled(true);

        fetch('/api/resend')
            .then((resp) => resp.json())
            .then(({success, user}) => {
                if (success && user) {
                    setTimerInterval(60);
                } else {
                    navigate('/')
                }

            })
            .finally(() => {

            })
            .catch(() => {

            })
    }
    const onSubmit = (data) => {

        const formData = new FormData()
        Object.keys(data).forEach((v) => formData.append(v, data[v]));

        fetch('/api/verify', {
            method: 'post',
            body: formData
        })
            .then((resp) => resp.json())
            .then(({success, user}) => {
                if (success) {
                    if (user && user.id) {
                        if (user.verified) {
                            dispatch({
                                type: 'setState',
                                payload: {
                                    user
                                }
                            })
                            navigate('/start');
                        } else {
                            setError('code', { type: "custom", message: "Введён неправильный код" })
                        }
                    }else {
                        navigate('/signup');
                    }

                }

            })
            .catch(() => {

            })
    }


    return <>

        <img src={s_bubble} alt="..." className="tb-bubble tb-bubble_02"/>

        <h2 className="tb-section__title">
            Введи код, который мы <br/> выслали на&nbsp;указанный мобильный телефон по&nbsp;SMS
        </h2>
        <form onSubmit={handleSubmit(onSubmit)} id="form-verify">
            <div className="tb-form">
                <div className={"tb-form__row" + (errors.code ? ' is-error' : '')}>
                    <div className="tb-form__input-holder">
                        <input {...register('code', {
                            'required' : 'Введите код'
                        })} type="number" min="1000" className="tb-form__input tb-form__input_short" placeholder="код"/>
                        {errors.code && <div className="tb-form__alert">{errors.code.message}</div>}
                    </div>

                </div>
                <div className="tb-form__row">
                    <div className="tb-form__note">
                        <p>
                            Отправить новый код можно через {formatTime(timerInterval)}
                        </p>
                        <p>
                            <button className="tb-btn tb-btn_link tb-btn_resend" onClick={resendCode} disabled={resendButtonDisabled}>Отправить код ещё раз
                            </button>
                        </p>
                    </div>
                </div>
            </div>

            <div className="tb-btns tb-btns_02">
                <button className="tb-btn tb-btn_cta" type="submit">Продолжить</button>
            </div>
        </form>
        <div className="tb-section__bottom">
            <img className="tb-signs2" src={signs2} alt=""/>
            <img src={bug01} alt="bug" className="tb-bug tb-bug_08"/>
            <img src={bug04} alt="bug" className="tb-bug tb-bug_09"/>
            <img src={bug03} alt="bug" className="tb-bug tb-bug_10"/>
        </div>


    </>
}

export default Verify;