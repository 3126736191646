import {
    Route,
    Link,
    Routes,
} from 'react-router-dom';
import './assets/style/style.scss'
import fp01 from "./assets/fp01.svg"
import logo from "./assets/logo.svg"
import bug01 from "./assets/bug01.svg"
import bug02 from "./assets/bug02.svg"
import Home from "./sections/home";
import Signup from "./sections/signup";
import Verify from "./sections/verify";
import Start from "./sections/start";
import Quiz from "./sections/quiz";
import Result from "./sections/result";
import Leaders from "./sections/leaders";

function App() {
    const NoMatch = () => {
        return (
            <section className="tb-section">
                <h2>Nothing to see here!</h2>
                <p>
                    <Link to="/">Go to the home page</Link>
                </p>
            </section>
        );
    }
    return (
        <div className="tb-main-wrapper tbuktopuha">

            <div className="tb-main-wrapper__bg">
                <img src={fp01} alt="" className="tb-fp tb-fp_01"/>
                <img src={fp01} alt="" className="tb-fp tb-fp_02"/>
            </div>

            <header className="tb-header">
                <div className="tb-inside-wrapper">

                    <div className="tb-logo">
                        <img src={logo} alt="IT'S TINKOFF"/>
                    </div>

                    <div className="tb-header__bugs">
                        <img src={bug01} alt="bug" className="tb-bug tb-bug_01"/>
                        <img src={bug02} alt="bug" className="tb-bug tb-bug_02"/>
                    </div>

                </div>
            </header>
            <main className="tb-main">
                <div className="tb-main__inner tb-inside-wrapper">
                    <section className="tb-section">
                        <Routes>
                            <Route path="/" element={<Home/>}/>
                            <Route path="/signup" element={<Signup/>}/>
                            <Route path="/verify" element={<Verify/>}/>
                            <Route path="/start" element={<Start/>}/>
                            <Route path="/quiz" element={<Quiz/>}/>
                            <Route path="/result" element={<Result/>}/>
                            <Route path="/leaders" element={<Leaders/>}/>
                            <Route path="*" element={<NoMatch/>}/>
                        </Routes>
                    </section>
                </div>
            </main>
        </div>
    )
}

export default App
